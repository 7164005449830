export default {
  "base-action": "_base-action_1c6fij",
  "action": "_action_1c6fij _base-action_1c6fij",
  "decent-action": "_decent-action_1c6fij _base-action_1c6fij",
  "header": "_header_1c6fij",
  "content": "_content_1c6fij",
  "brand": "_brand_1c6fij",
  "logo": "_logo_1c6fij",
  "icons-section": "_icons-section_1c6fij",
  "sections": "_sections_1c6fij",
  "actions": "_actions_1c6fij",
  "sections-link": "_sections-link_1c6fij _action_1c6fij _base-action_1c6fij",
  "has-unread": "_has-unread_1c6fij",
  "has-dot": "_has-dot_1c6fij",
  "disabled": "_disabled_1c6fij",
  "icon": "_icon_1c6fij",
  "icon-messenger": "_icon-messenger_1c6fij",
  "icon-profile": "_icon-profile_1c6fij",
  "icon-burger": "_icon-burger_1c6fij",
  "icon-close": "_icon-close_1c6fij",
  "messenger": "_messenger_1c6fij",
  "notification-centre": "_notification-centre_1c6fij",
  "active": "_active_1c6fij",
  "profile": "_profile_1c6fij",
  "profile__label": "_profile__label_1c6fij",
  "profile-menu": "_profile-menu_1c6fij",
  "profile-menu-user": "_profile-menu-user_1c6fij",
  "profile-menu-user-link": "_profile-menu-user-link_1c6fij _action_1c6fij _base-action_1c6fij",
  "profile-menu-user-link__wrapper": "_profile-menu-user-link__wrapper_1c6fij",
  "profile-menu-legal": "_profile-menu-legal_1c6fij",
  "profile-menu-legal-link": "_profile-menu-legal-link_1c6fij _decent-action_1c6fij _base-action_1c6fij",
  "profile-menu-legal-link__wrapper": "_profile-menu-legal-link__wrapper_1c6fij",
  "legal-links-wrapper": "_legal-links-wrapper_1c6fij",
  "login-link": "_login-link_1c6fij",
  "partner-logo-wrapper": "_partner-logo-wrapper_1c6fij",
  "partner-logo": "_partner-logo_1c6fij",
  "icon-button": "_icon-button_1c6fij",
  "profile-icon": "_profile-icon_1c6fij",
  "popover-container": "_popover-container_1c6fij",
  "profile-button": "_profile-button_1c6fij",
  "app-referral": "_app-referral_1c6fij",
  "app-referral-icon": "_app-referral-icon_1c6fij",
  "pill-label": "_pill-label_1c6fij"
};
