export default {
  "link": "_link_86z4bl",
  "container": "_container_86z4bl",
  "header": "_header_86z4bl",
  "title": "_title_86z4bl",
  "body": "_body_86z4bl",
  "body-background": "_body-background_86z4bl",
  "agent-name": "_agent-name_86z4bl",
  "agent-description": "_agent-description_86z4bl",
  "footer": "_footer_86z4bl",
  "footer-cta": "_footer-cta_86z4bl",
  "footer-cta-icon": "_footer-cta-icon_86z4bl",
  "footer-cta-label": "_footer-cta-label_86z4bl"
};
