export default {
  "slide-description": "_slide-description_vka334",
  "slide-content": "_slide-content_vka334",
  "title-container": "_title-container_vka334",
  "media-container": "_media-container_vka334",
  "sized-image": "_sized-image_vka334",
  "slide-container": "_slide-container_vka334",
  "header": "_header_vka334",
  "logo-container": "_logo-container_vka334",
  "brand-logo": "_brand-logo_vka334"
};
