export default {
  "section-wrapper": "_section-wrapper_17ad4r",
  "heading": "_heading_17ad4r",
  "phone-title": "_phone-title_17ad4r",
  "phone-subtitle": "_phone-subtitle_17ad4r",
  "code-title": "_code-title_17ad4r",
  "code-subtitle": "_code-subtitle_17ad4r",
  "phone-number": "_phone-number_17ad4r",
  "subtitle-button": "_subtitle-button_17ad4r",
  "subtitle-button-text": "_subtitle-button-text_17ad4r"
};
