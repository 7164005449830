export default {
  "question": "_question_73ill7",
  "question__intro": "_question__intro_73ill7",
  "question__intro__question": "_question__intro__question_73ill7",
  "question__intro__question--optional": "_question__intro__question--optional_73ill7",
  "question__intro__heading": "_question__intro__heading_73ill7",
  "question__hint": "_question__hint_73ill7",
  "question__hint__text": "_question__hint__text_73ill7",
  "explanation-mark": "_explanation-mark_73ill7",
  "explanation-body": "_explanation-body_73ill7"
};
