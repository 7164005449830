export default {
  "preselected-category-card": "_preselected-category-card_12f6q6",
  "is-flipped": "_is-flipped_12f6q6",
  "positioning-container": "_positioning-container_12f6q6",
  "content-container": "_content-container_12f6q6",
  "content-container-flipside": "_content-container-flipside_12f6q6",
  "is-hidden": "_is-hidden_12f6q6",
  "image": "_image_12f6q6",
  "content": "_content_12f6q6",
  "actions": "_actions_12f6q6",
  "title": "_title_12f6q6",
  "subtitle": "_subtitle_12f6q6",
  "description": "_description_12f6q6",
  "action-icon": "_action-icon_12f6q6"
};
