export default {
  "container": "_container_1kja2z",
  "error-page": "_error-page_1kja2z",
  "error-intro": "_error-intro_1kja2z",
  "error-details": "_error-details_1kja2z",
  "icon": "_icon_1kja2z",
  "title": "_title_1kja2z",
  "text-default": "_text-default_1kja2z",
  "error-description": "_error-description_1kja2z"
};
