export default {
  "form-panel": "_form-panel_lhxdff _form-panel_6nd4i3",
  "wizard-profiling-section-wrapper": "_wizard-profiling-section-wrapper_lhxdff _wizard-profiling-section-wrapper_6nd4i3",
  "form-panel--center": "_form-panel--center_lhxdff",
  "payback__wrapper": "_payback__wrapper_lhxdff",
  "payback__account_number": "_payback__account_number_lhxdff",
  "payback__input": "_payback__input_lhxdff",
  "payback__info": "_payback__info_lhxdff",
  "payback__points_wrapper": "_payback__points_wrapper_lhxdff",
  "payback__points_title": "_payback__points_title_lhxdff",
  "points__summary": "_points__summary_lhxdff",
  "payback__account_link": "_payback__account_link_lhxdff",
  "payback__money_icon": "_payback__money_icon_lhxdff",
  "payback__link": "_payback__link_lhxdff",
  "payback__section-title": "_payback__section-title_lhxdff"
};
