export default {
  "sign-container": "_sign-container_6b4szq",
  "confirmation-container": "_confirmation-container_6b4szq",
  "sign-header": "_sign-header_6b4szq",
  "sign-description": "_sign-description_6b4szq",
  "info-modal": "_info-modal_6b4szq",
  "subtitle-button": "_subtitle-button_6b4szq",
  "subtitle-button-text": "_subtitle-button-text_6b4szq",
  "terms-conditions": "_terms-conditions_6b4szq",
  "clark2": "_clark2_6b4szq",
  "terms-conditions__link": "_terms-conditions__link_6b4szq"
};
