export default {
  "cookie-banner-container": "_cookie-banner-container_o62h22",
  "cookie-banner-contents": "_cookie-banner-contents_o62h22",
  "cookie-banner-category": "_cookie-banner-category_o62h22",
  "cookie-banner-inner": "_cookie-banner-inner_o62h22",
  "header": "_header_o62h22",
  "header-label": "_header-label_o62h22",
  "description": "_description_o62h22",
  "more-information": "_more-information_o62h22",
  "cookie-banner-collapse": "_cookie-banner-collapse_o62h22",
  "cookie-banner-ctas-primary": "_cookie-banner-ctas-primary_o62h22",
  "cookie-banner-ctas-secondary": "_cookie-banner-ctas-secondary_o62h22",
  "cookie-banner-legal-links": "_cookie-banner-legal-links_o62h22",
  "link": "_link_o62h22"
};
