export default {
  "form-panel": "_form-panel_1rgpxj _form-panel_6nd4i3",
  "wizard-profiling-section-wrapper": "_wizard-profiling-section-wrapper_1rgpxj _wizard-profiling-section-wrapper_6nd4i3",
  "form-panel--center": "_form-panel--center_1rgpxj",
  "mam__wrapper": "_mam__wrapper_1rgpxj",
  "mam__account_number": "_mam__account_number_1rgpxj",
  "mam__input": "_mam__input_1rgpxj",
  "mam__points_wrapper": "_mam__points_wrapper_1rgpxj",
  "mam__field_title": "_mam__field_title_1rgpxj",
  "points__summary": "_points__summary_1rgpxj",
  "mam__section-title": "_mam__section-title_1rgpxj"
};
