export default {
  "base-action": "_base-action_1wcjfq",
  "action": "_action_1wcjfq _base-action_1wcjfq",
  "decent-action": "_decent-action_1wcjfq _base-action_1wcjfq",
  "header": "_header_1wcjfq",
  "header--iphoneX-adjustments-on": "_header--iphoneX-adjustments-on_1wcjfq",
  "sections": "_sections_1wcjfq",
  "actions": "_actions_1wcjfq",
  "sections-link": "_sections-link_1wcjfq _action_1wcjfq _base-action_1wcjfq",
  "actions-link": "_actions-link_1wcjfq _action_1wcjfq _base-action_1wcjfq",
  "active-link": "_active-link_1wcjfq",
  "menu-item": "_menu-item_1wcjfq",
  "disabled": "_disabled_1wcjfq",
  "inactive-link": "_inactive-link_1wcjfq",
  "icon": "_icon_1wcjfq",
  "sections-label": "_sections-label_1wcjfq",
  "actions-label": "_actions-label_1wcjfq",
  "icon-contract": "_icon-contract_1wcjfq",
  "messenger": "_messenger_1wcjfq",
  "has-messages": "_has-messages_1wcjfq"
};
