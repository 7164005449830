export default {
  "token-body": "_token-body_77s0dc",
  "token-body-title": "_token-body-title_77s0dc",
  "phone-verification-input-code": "_phone-verification-input-code_77s0dc",
  "has-error": "_has-error_77s0dc",
  "token-input-wrapper": "_token-input-wrapper_77s0dc",
  "tick-symbol": "_tick-symbol_77s0dc",
  "token-error-wrapper": "_token-error-wrapper_77s0dc",
  "link": "_link_77s0dc"
};
