export default {
  "container": "_container_z37i4n",
  "terms-of-service": "_terms-of-service_z37i4n",
  "legal-link": "_legal-link_z37i4n",
  "legal-link-secondary": "_legal-link-secondary_z37i4n",
  "consent": "_consent_z37i4n",
  "consent-container": "_consent-container_z37i4n",
  "consent-title": "_consent-title_z37i4n",
  "consent-content": "_consent-content_z37i4n"
};
