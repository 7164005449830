export default {
  "title": "_title_16bny6",
  "container": "_container_16bny6",
  "clark-mobile-image": "_clark-mobile-image_16bny6",
  "app-download-link": "_app-download-link_16bny6",
  "advantage-list-title": "_advantage-list-title_16bny6",
  "advantage-list": "_advantage-list_16bny6",
  "advantage-list-item": "_advantage-list-item_16bny6",
  "advantage-list-item-icon": "_advantage-list-item-icon_16bny6",
  "points-heading": "_points-heading_16bny6",
  "points-content": "_points-content_16bny6",
  "not-now-link-wrapper": "_not-now-link-wrapper_16bny6"
};
