export default {
  "header": "_header_f8070v",
  "title": "_title_f8070v",
  "tagline": "_tagline_f8070v",
  "trust-icons": "_trust-icons_f8070v",
  "body": "_body_f8070v",
  "remind-me-later": "_remind-me-later_f8070v",
  "answer-options": "_answer-options_f8070v",
  "continue": "_continue_f8070v"
};
