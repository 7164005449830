export default {
  "header-logo": "_header-logo_11q2a3",
  "logo-svg": "_logo-svg_11q2a3",
  "form-wrapper": "_form-wrapper_11q2a3",
  "reveal_wrapper__eye": "_reveal_wrapper__eye_11q2a3",
  "form-subtitle": "_form-subtitle_11q2a3",
  "form-input": "_form-input_11q2a3",
  "base-label": "_base-label_11q2a3",
  "button-container": "_button-container_11q2a3",
  "intro": "_intro_11q2a3",
  "base-action": "_base-action_11q2a3",
  "form-options": "_form-options_11q2a3",
  "font-small": "_font-small_11q2a3",
  "login-button-for-apple": "_login-button-for-apple_11q2a3",
  "icon": "_icon_11q2a3"
};
