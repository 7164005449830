export default {
  "wizard-profiling": "_wizard-profiling_6nd4i3",
  "wizard-profiling-section-wrapper": "_wizard-profiling-section-wrapper_6nd4i3",
  "form-panel": "_form-panel_6nd4i3",
  "floater__button": "_floater__button_6nd4i3",
  "floater-container": "_floater-container_6nd4i3",
  "mandate__doc-wrapper": "_mandate__doc-wrapper_6nd4i3",
  "mandate__cookies-settings": "_mandate__cookies-settings_6nd4i3",
  "heading": "_heading_6nd4i3",
  "title": "_title_6nd4i3",
  "subtitle": "_subtitle_6nd4i3",
  "form-label": "_form-label_6nd4i3",
  "form-cell": "_form-cell_6nd4i3",
  "field-group": "_field-group_6nd4i3",
  "custom-cell-width": "_custom-cell-width_6nd4i3",
  "left-align-text": "_left-align-text_6nd4i3",
  "form-layout": "_form-layout_6nd4i3",
  "form-block": "_form-block_6nd4i3",
  "direction-horizontal": "_direction-horizontal_6nd4i3",
  "form-filling-help": "_form-filling-help_6nd4i3",
  "mandate-id-label": "_mandate-id-label_6nd4i3",
  "mandate-id": "_mandate-id_6nd4i3",
  "delete-mandate-container": "_delete-mandate-container_6nd4i3",
  "delete-mandate-link": "_delete-mandate-link_6nd4i3"
};
