export default {
  "section": "_section_1x3s4f _section_eamcx0 _section--grey_eamcx0",
  "wrapper": "_wrapper_1x3s4f _wrapper_eamcx0 section__wrapper",
  "reasons": "_reasons_1x3s4f",
  "why": "_why_1x3s4f",
  "why__title": "_why__title_1x3s4f",
  "why__subtitle": "_why__subtitle_1x3s4f",
  "why__content": "_why__content_1x3s4f",
  "guarantee": "_guarantee_1x3s4f",
  "guarantee__title": "_guarantee__title_1x3s4f",
  "guarantee__items": "_guarantee__items_1x3s4f",
  "guarantee__items__item": "_guarantee__items__item_1x3s4f",
  "guarantee__items__item__icon": "_guarantee__items__item__icon_1x3s4f",
  "guarantee__items__item__content": "_guarantee__items__item__content_1x3s4f",
  "guarantee__items__item__content__title": "_guarantee__items__item__content__title_1x3s4f",
  "guarantee__items__item__content__text": "_guarantee__items__item__content__text_1x3s4f",
  "guarantee__trust": "_guarantee__trust_1x3s4f"
};
