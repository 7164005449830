export default {
  "intro": "_intro_1tqnka",
  "guarantee": "_guarantee_1tqnka",
  "header": "_header_1tqnka",
  "body": "_body_1tqnka",
  "heading": "_heading_1tqnka",
  "message": "_message_1tqnka",
  "info": "_info_1tqnka",
  "info__subsection": "_info__subsection_1tqnka",
  "cta": "_cta_1tqnka",
  "cta-section": "_cta-section_1tqnka",
  "mins": "_mins_1tqnka",
  "text": "_text_1tqnka",
  "medium": "_medium_1tqnka",
  "title": "_title_1tqnka"
};
