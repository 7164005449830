export default {
  "payback-calculator-wrapper": "_payback-calculator-wrapper_1ff2az",
  "payback-calculator-info-wrapper": "_payback-calculator-info-wrapper_1ff2az",
  "payback-calculator-info-content": "_payback-calculator-info-content_1ff2az",
  "payback-calculator-title": "_payback-calculator-title_1ff2az",
  "payback-money-icon": "_payback-money-icon_1ff2az",
  "payback-calculator-points": "_payback-calculator-points_1ff2az",
  "payback-calculator-info": "_payback-calculator-info_1ff2az",
  "info-icon-wrapper": "_info-icon-wrapper_1ff2az"
};
