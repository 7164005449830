export default {
  "contracts-wrapper": "_contracts-wrapper_3hjjf2",
  "page-wrapper": "_page-wrapper_3hjjf2",
  "card-list-container": "_card-list-container_3hjjf2",
  "cards": "_cards_3hjjf2",
  "header": "_header_3hjjf2",
  "title": "_title_3hjjf2",
  "greeting": "_greeting_3hjjf2",
  "header-top": "_header-top_3hjjf2",
  "header-inner": "_header-inner_3hjjf2"
};
