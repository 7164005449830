export default {
  "list-container": "_list-container_12v4cz",
  "list": "_list_12v4cz",
  "list-item": "_list-item_12v4cz",
  "item": "_item_12v4cz",
  "item-svg": "_item-svg_12v4cz",
  "term-title": "_term-title_12v4cz",
  "item-description": "_item-description_12v4cz",
  "terms-title": "_terms-title_12v4cz"
};
