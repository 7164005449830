import type { NamedArgs, PositionalArgs } from 'ember-modifier';
import { modifier } from 'ember-modifier';
import window from 'ember-window-mock';

export interface RootPropertyModifierSignature {
  Args: {
    Named: {
      name: string;
      value: string;
    };
    Positional: [];
  };
  Element: Element;
}

export default modifier<RootPropertyModifierSignature>(
  (
    _element: Element,
    _positional: PositionalArgs<RootPropertyModifierSignature>,
    named: NamedArgs<RootPropertyModifierSignature>,
  ) => {
    const { name, value } = named;

    window.document.documentElement.style.setProperty(name, value);

    return () => {
      window.document.documentElement.style.removeProperty(name);
    };
  },
);
