export default {
  "mandate-home24-wrapper": "_mandate-home24-wrapper_1rvbzr",
  "mandate-home24-title": "_mandate-home24-title_1rvbzr",
  "mandate-home24-checkbox-list": "_mandate-home24-checkbox-list_1rvbzr",
  "popup-hero-text": "_popup-hero-text_1rvbzr",
  "popup-trust-icons": "_popup-trust-icons_1rvbzr",
  "popup-trust-list": "_popup-trust-list_1rvbzr",
  "heading": "_heading_1rvbzr",
  "subtitle": "_subtitle_1rvbzr",
  "subtitle-button": "_subtitle-button_1rvbzr",
  "subtitle-button-text": "_subtitle-button-text_1rvbzr"
};
