export default {
  "category": "_category_eamcx0",
  "wrapper": "_wrapper_eamcx0 section__wrapper",
  "section": "_section_eamcx0",
  "section--grey": "_section--grey_eamcx0",
  "section--dark": "_section--dark_eamcx0",
  "section--dark--spaced": "_section--dark--spaced_eamcx0",
  "inset-section": "_inset-section_eamcx0 _section_ger4tz _section--grey_ger4tz",
  "related-recommendations": "_related-recommendations_eamcx0"
};
